// import { VerificationSessionPayload } from "@privateid/cryptonets-web-sdk";
import identityAPI from "./orchestration";
import cryptonetsAPI from "./index";
import axios from "axios";
import develApi from "./develOrchestration";


export const createVerificationSession = async (payload: any) => {
  try {
    const result = await identityAPI.post(`/v2/verification-session`, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const updateDocumentUploadIdWithSession = async ({
  sessionToken,
  documentId,
  content,
}: {
  sessionToken: string;
  documentId: string;
  content: any;
}) => {
  try {
    const result = await identityAPI.put(
      `/v2/verification-session/${sessionToken}/document/${documentId}`,
      { content }
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const verifyTokenApi = async (id: any) => {
  try {
    const result = await identityAPI.get(`/v2/verification-session/${id}`);
    return result;
  } catch (err) {
    return err;
  }
};

export const getUserPortrait = async (token: any) => {
  try {
    const type = "portrait";

    const result = await identityAPI.get(
      `/v2/verification-session/${token}/img/${type}`
    );
    return result;
  } catch (err) {
    return err;
  }
};

export const getUser = async (payload: any) => {
  try {
    const result = await identityAPI.post(`/v2/users/get`, payload);
    return result;
  } catch (err) {
    return err;
  }
};

export const sendMessage = async (payload: any) => {
  try {
    const result = await cryptonetsAPI.post(`/user/communicate`, payload);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getOrganizationConfig = async (organization: string, lang?: string) => {
  try {
    const result = await develApi.get(
      `v2/organization-data/configuration?organization=${organization}&lang=${lang || 'en'}`
    );
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};


export const generateOtp = async (token: string, payload: {uuid: string, guid: string}) => {
  try {
    const result = await identityAPI.post(`v2/verification-session/${token}/gen-pin`, payload);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const verifyOtp = async (token: string, payload: any) => {
  try {
    const result = await identityAPI.post(`v2/verification-session/${token}/verify-pin`, payload);
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const verifyBackDl = async (
  userId: string,
  payload: {
    barcodeHash64_string: string;
    type: string;
  }
) => {
  try {
    const result = await identityAPI.post(
      `/v2/users/verify/document/${userId}`,
      payload
    );
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPrivacy = async (lang?: string) => {
  try {
    const result = await develApi.get(
      `v2/organization-data/privacy?lang=${lang || 'en'}`
    );
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getTerms = async (lang?: string) => {
  try {
    const result = await develApi.get(
      `v2/organization-data/terms?lang=${lang || 'en'}`
    );
    return result;
  } catch (err) {
    console.log(err);
    return err;
  }
};