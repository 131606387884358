import { useContext, useEffect, useState } from "react";
import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { UserContext } from "context/userContext";
import { closeCamera } from "@privateid/cryptonets-web-sdk";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { ELEMENT_ID } from "constant";
import SwitchDeviceSelect from "common/components/switchDeviceSelect";
import { useNavigation } from "utils/onNavigate";
import useMultiFramePredictAge from "hooks/useMultiFramePredictAge";
import { useSearchParams } from "react-router-dom";

function PredictAge() {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateToNextPage } = useNavigation();
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [scanCompleted, setScanCompleted] = useState(false);
  const isAgeFlow = context?.configuration?.redirectUrl === "isAgeFlow"
  const [bestImage, setBestImage] = useState("")
  const [searchParams] = useSearchParams();
  const onAgeSuccess = () => {
    setTimeout(async () => {
      await closeCamera(ELEMENT_ID);
      if (context?.loginOption === "predictAge-Dl") {
        if (context?.age < (searchParams.get("threshold") || 80)) {
          navigateToNextPage("", "/drivers-licence-intro", "/predict-age");
          setProgress(0);
        } else {
          navigateToNextPage("", "/age-success", "/predict-age");
          setProgress(0);
        }
      } else {
        navigateToNextPage("", "/age-success", "/predict-age");
        setProgress(0);
      }
    }, 2000);
  };

  useEffect(() => {
    const convertBase64ToImageData = async (base64?: string) => {
      return new Promise((resolve, reject) => {
        if (!base64) {
          reject("Base64 string is empty or undefined");
          return;
        }
  
        const img = new Image();
  
        // Add error handling
        img.onerror = () => reject("Failed to load the image. Invalid Base64 string or missing MIME type.");
  
        img.onload = () => {
          const width = img.width;
          const height = img.height;
  
          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;
  
          const ctx = canvas.getContext("2d");
          if (!ctx) {
            reject("Failed to get 2D context from canvas");
            return;
          }
  
          ctx.drawImage(img, 0, 0);
  
          const imageData = ctx.getImageData(0, 0, width, height);
          resolve(imageData);
        };
  
        // Ensure MIME type is included
        img.src = base64.startsWith("data:image")
          ? base64
          : `data:image/png;base64,${base64}`;
      });
    };
  
    const init = async () => {
      if (bestImage) {
        try {
          const imageData = await convertBase64ToImageData(bestImage);
          context.setUser({
            ...context.user,
            enrollImageData: imageData,
          });
        } catch (error) {
          console.error("Error converting Base64 to ImageData:", error);
        }
      }
    };
  
    init();
  }, [bestImage, context]);

  const onSuccess = (resultAge: any, portrait: any) => {
    if (isAgeFlow) {
      setBestImage(portrait);
    }
    const userAge = Math.round(resultAge);
    context.setAge(userAge);
    setProgress(100);
    setScanCompleted(true);
  };
  const { doPredictAge, statusMessage, progress, setProgress } =
    useMultiFramePredictAge(onSuccess);
  const onCameraReady = () => {
    doPredictAge();
  };

  return (
    <Layout removeHeight={!isCameraGranted}>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative">
          <BackButton isCamera />
          <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
            <img src={lock} alt="lock" className="mr-[5px]" />
            {pageConfig?.title || "Biometrics never leave this device"}
          </div>
        </div>
        <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center">
          <CameraComponent
            faceCamera={true}
            onCameraReady={onCameraReady}
            progress={progress}
            message={statusMessage}
            onSuccess={onAgeSuccess}
            attempt={statusMessage ? 1 : 0}
            scanCompleted={scanCompleted}
          />
        </div>
      </div>
      <div className="mt-[-40px]">
        <SwitchDeviceSelect />
      </div>
    </Layout>
  );
}

export default PredictAge;
