import { useContext, useState } from "react";
import { predictAge } from "@privateid/cryptonets-web-sdk";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "context/userContext";

let skipAntispoofGlobal: any = false;
let max_spoof_attempt = 5;
let spoof_attempt = 0;
let mf_count = 3;
const useMultiFramePredictAge = (onSuccess: {
  (resultAge: any, portrait: any): void;
  (arg0: any, arg1: any): void;
}) => {
  const context: any = useContext(UserContext);
  const configuration = context?.configuration;
  const [age, setAge] = useState<any>(null);
  const [antispoofPerformed, setAntispoofPerformed] = useState<any>(false);
  const [antispoofStatus, setAntispoofStatus] = useState<string>("");
  const [validationStatus, setValidationStatus] = useState<string>("");
  const [statusMessage, setStatusMessage] = useState<any>("");
  const [progress, setProgress] = useState(0);
  const isAgeFlow = configuration?.redirectUrl === "isAgeFlow";
  const [searchParams] = useSearchParams();
  const mf_count_override = searchParams.get("mf_count")
    ? Number(searchParams.get("mf_count"))
    : mf_count;
  max_spoof_attempt = searchParams.get("spoof_attempt")
    ? Number(searchParams.get("spoof_attempt"))
    : max_spoof_attempt;
  const mfCallback = (result: any) => {
    console.log(result);

    if (result?.age && result?.age > 0) {
      setAntispoofStatus(result?.antispoof_status);
      setAntispoofPerformed(result?.anti_spoof_performed);
      setValidationStatus(result?.face_validation_status);
      setStatusMessage(getStatusMessage(result?.face_validation_status));
      setAge(result?.age);
      onSuccess(result?.age, result?.portrait);
    }
    if (result?.antispoof_status === 1 && isAgeFlow) {
      spoof_attempt++;
      setProgress(0);
      if (spoof_attempt >= max_spoof_attempt) {
        alert("Spoof attempt exceeded");
        window.location.href = "/";
      } else {
        doPredictAge(skipAntispoofGlobal, result?.mf_token);
      }
    } else {
      setAge("");
      setAntispoofStatus(result?.antispoof_status);
      setValidationStatus(result?.face_validation_status);
      doPredictAge(skipAntispoofGlobal, result?.mf_token);
      setStatusMessage(getStatusMessage(result?.face_validation_status));
    }
  };

  const doPredictAge = async (skipAntispoof = false, mfToken = "") => {
    if (mfToken) {
      if (isAgeFlow) {
        setProgress((p) => {
          if (p >= 100) return 100;
          return p + 100 / mf_count_override;
        });
      } else {
        setProgress((p) => p + 20);
      }
    } else {
      setProgress(0);
    }
    skipAntispoofGlobal = skipAntispoof;
    await predictAge({
      callback: mfCallback,
      config: {
        ...(isAgeFlow
          ? {
              skip_antispoof:
                searchParams.get("skipAntispoof") === "true" ||
                !configuration?.liveness ||
                false,
              mf_count_override,
            }
          : {}),
        mf_token: mfToken,
      },
    });
  };

  return {
    doPredictAge,
    age,
    antispoofPerformed,
    antispoofStatus,
    validationStatus,
    statusMessage,
    progress,
    setProgress
  };
};

export default useMultiFramePredictAge;
