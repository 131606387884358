import { useContext, useEffect } from "react";
import Lottie from "lottie-react";
import { UserContext } from "context/userContext";
import successJson from "Animations/5-Verify/JSONs/Success.json";
import Layout from "common/layout";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { extractRoute, isMobile } from "utils";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { useNavigation } from "utils/onNavigate";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";

type Props = {
  heading?: string;
};

function Success(Props: Props) {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const uuid = localStorage.getItem("uuid");
  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    context?.setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(
      context?.configuration,
      currentRouteIndex
    );
    context?.setLoginOption("");
    context?.setPageConfiguration(pageConfiguration);
  };
  const onSignout = () => {
    if (context?.configuration?.isKantara && pageConfig?.type !== "completed") {
      navigateToNextPage();
      return;
    }
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    loadPageConfiguration();
    if (pageConfig) {
      navigateToNextPage("token=", "/", "/success");
    } else {
      navigateWithQueryParams("/", { token: undefined });
    }
  };
  const onSecondaryButton = () => {
    localStorage.removeItem("uuid");
    loadPageConfiguration();
    if (pageConfig) {
      navigateToNextPage("", "/", "/success");
    } else {
      navigateWithQueryParams("/", { token: undefined });
    }
  };
  useEffect(() => {
    context.setLoginOption("");
  }, []);

  const backToHome = () => {
    loadPageConfiguration();
    navigateWithQueryParams("/", { token: undefined });
  };
  return (
    <Layout>
      <div className="h-full px-10 py-8 flex justify-between items-center flex-col max-md:p-[20px]">
        <div className="w-full">
          <div className="flex justify-between relative  z-50">
            <BackButton />
            <CloseButton />
          </div>
          <div className="flex justify-center items-center mb-[-5rem] mt-[-4rem]">
            <Lottie
              loop={false}
              autoplay={true}
              animationData={successJson}
              style={{
                height: isMobile ? 350 : 400,
                width: isMobile ? 380 : 400,
              }}
            />
          </div>
          {pageConfig?.type === "ageSuccess" ? (
            context?.age && context?.user?.document_age ? (
              <Label className="text-[23px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem]">
                <span className="block">
                  Success! Your age is {context?.age}.
                </span>
                <span className="block">
                  The document's age is {context?.user?.document_age}.
                </span>
                <span className="block">
                  Document and image comparison score is {context?.user?.compareScore}.
                </span>
              </Label>
            ) : (
              <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem]">
                {pageConfig?.message} {context.age}
              </Label>
            )
          ) : (
            <>
              <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem]">
                {pageConfig?.message ||
                  context?.successMessage ||
                  Props.heading ||
                  "Success! Your account is created"}
              </Label>
              <br />
              <Label className="text-[14px] font-[400] text-secondaryText">
                uuid: {uuid?.slice(1, -1)}
              </Label>
            </>
          )}
          {pageConfig?.type === "completed" && (
            <p className="text-[14px] font-[400]">
              A text message was sent to your phone to confirm your Private ID
              account. No personal details, images or biometric data are
              retained. This account will expire after two years of inactivity.
            </p>
          )}
        </div>
        <div
          className={`text-left ${
            pageConfig?.secondaryButton ? "h-[154px]" : "h-[100px]"
          } absolute w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset] max-md:relative`}
        >
          <div>
            <Button
              className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
              onClick={() => onSignout()}
            >
              {pageConfig?.buttonText || "Sign out"}
            </Button>
            {pageConfig?.secondaryButton && (
              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
                onClick={() =>
                  context?.configuration?.isKantara
                    ? onSecondaryButton()
                    : pageConfig
                    ? backToHome()
                    : navigateWithQueryParams("/login-options", {
                        token: undefined,
                      })
                }
              >
                {pageConfig?.secondaryButton || "Return to options"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Success;
