import { useContext, useEffect, useState } from "react";
import useWasm from "hooks/useWasm";
import { getOrganizationFromURL, isMobile, stopCamera } from "utils";
import { CameraConfig, ELEMENT_ID, ENROLL_CANVAS_RESOLUTION } from "constant";
import {
  closeCamera,
  createDocumentUploadIdTypeEnum,
  createDocumentUploadIdWithSession,
  updateTypeEnum,
  updateUserWithSession,
  uploadDocumentImageWithSession,
  documentImageTypeEnum,
  verifyIdWithSession,
  verifySessionTokenV2,
} from "@privateid/cryptonets-web-sdk";
import useCamera from "hooks/useCamera";
import useCameraPermissions from "hooks/useCameraPermissions";
import ProveLogo from "assets/proveLogo.png";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import Lottie from "lottie-react";
import completedCheck from "Animations/4-Capture-successfully/JSON/confetti.json";
import { getFrontDocumentStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import frontDlImage from "assets/frontDl.svg";
import useScanPassport from "hooks/useScanPassport";
import { issueCredentials } from "services/vc-dock";
import {
  generateRegistrationOptions,
  verifyRegistration,
} from "services/passkey";
import { startRegistration } from "@simplewebauthn/browser";
import FaceAnimationProve from "common/animateCircle/faceScanAnimationProve";

const rendererSettings = {
  preserveAspectRatio: "xMaxYMin slice",
};

const PassportScan = ({ setStep }: { setStep: (e: number) => void }) => {
  const context: any = useContext(UserContext);
  const [deviceId, setDeviceId] = useState<string>();
  const [phone, setPhone] = useState<string>("");
  const [showPhone, setShowPhone] = useState(false);
  const projectName = getOrganizationFromURL();
  const { isCameraGranted }: any = useCameraPermissions(() => {});
  const { navigateToNextPage } = useNavigation();
  const [minimizeCamera, setMinimizeCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isCompletedPredict, setIsCompletedPredict] = useState(false);
  const [overlay, setOverlay] = useState(true);

  // Camera and Wasm init
  const onCameraFail = () => {
    //   cameraFail?.();
  };
  const cameraReady = () => {
    //   onCameraReady?.();
    onCameraReady();
  };
  const documentScan = true;
  const { ready: wasmReady, wasmStatus } = useWasm();
  const canvasResolution =
    !isMobile && !documentScan ? ENROLL_CANVAS_RESOLUTION : null;
  const { ready, init, device, devices } = useCamera(
    CameraConfig?.elementId,
    documentScan ? "back" : (CameraConfig?.mode as any),
    CameraConfig?.requireHD,
    onCameraFail,
    documentScan,
    canvasResolution
  );
  useEffect(() => {
    if (device) {
      setDeviceId(device);
    }
  }, [device]);
  console.log(wasmStatus,'wasmStatus', ready);

  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (wasmReady && !wasmStatus.isChecking && wasmStatus.support) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        cameraReady();
      }
    }
    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onCameraFail();
    }
  };
  useEffect(() => {
    handleWasmLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasmReady, ready, wasmStatus]);
  const onStatus = () => {};
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };

  const onDocumentSuccess = async () => {
    await closeCamera(ELEMENT_ID);
    await stopCamera();
  };

  const onSuccess = async (
    result:
      | {
          croppedDocument: string;
          croppedMugshot: string;
          inputImage: string;
          portraitConfScore: number;
        }
      | any
  ) => {
    setCompleted(true);
    const {
      inputImage,
      croppedDocument,
      croppedMugshot,
      portraitConfScore: compareScore,
    } = result;
    const documentId = await createDocumentUploadIdWithSession({
      documentType: createDocumentUploadIdTypeEnum.drivers_license,
      sessionToken: context?.tokenParams,
    });

    context.setUser({ ...context.user, documentId: documentId.id });
    setIsCompletedPredict(true);
    const payload = {
      sessionToken: context?.tokenParams,
      portrait_conf_score: compareScore.toFixed(2).toString(),
      updateType: updateTypeEnum.compare,
    };
    updateUserWithSession(payload);
    async function uploadAllImagesAndUpdateUser() {
      const promises = [
        // uploadDocumentImageWithSession({
        //   sessionToken: context?.tokenParams,
        //   documentImageType: documentImageTypeEnum.FRONT,
        //   documentId: documentId.id,
        //   imageString: inputImage,
        // }),
        uploadDocumentImageWithSession({
          sessionToken: context?.tokenParams,
          documentImageType: documentImageTypeEnum.FRONT_CROPPED_DOCUMENT,
          documentId: documentId.id,
          imageString: croppedDocument,
        }),
        uploadDocumentImageWithSession({
          sessionToken: context?.tokenParams,
          documentImageType: documentImageTypeEnum.FRONT_MUGSHOT,
          documentId: documentId.id,
          imageString: croppedMugshot,
        }),
      ];

      await Promise.all(promises);
    }

    if (context?.configuration?.uploadToServer) {
      // Call the function to upload images and update user
      uploadAllImagesAndUpdateUser()
        .then(() => {
          console.log("All uploads and update completed successfully.");
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  };
  const { scanFrontDocument, resultStatus } = useScanPassport(
    onSuccess,
    context?.user?.enrollImageData
  ) as any;

  const onCameraReady = () => {
    scanFrontDocument(true, context?.user?.enrollImageData);
  };

  useEffect(() => {
    if (isCompletedPredict && !showPhone) {
      setCompleted(true);
      onDocumentSuccess();
    }
  }, [isCompletedPredict, showPhone]);

  useEffect(() => {
    if (phone?.length >= 12) {
      setShowPhone(false);
    }
  }, [phone]);

  const handleAnimationComplete = (state: string) => {
    if (state === "start") {
      // setStartAnimation(true);
    } else if (state === "completed") {
      setCompleted(true);
      onDocumentSuccess();
    }
  };

  useEffect(() => {
    if (completed) {
      setTimeout(() => {
        setStep(6);
      }, 2000);
    }
  }, [completed]);

  useEffect(() => {
    setTimeout(() => {
      setOverlay(false);
    }, 3000);
  }, []);

  const status =
    resultStatus === 0
      ? "Success"
      : getFrontDocumentStatusMessage(resultStatus);

  return (
    <>
      <div className="bg-[#000] w-[100%] absolute top-[0px] left-[0px] max-md:w-[100%] h-[100vh] max-md:top-[0px] max-md:h-[calc(100vh_-80px)] flex flex-col items-center justify-center">
        <img
          src={ProveLogo}
          alt="ProveLogo"
          className="absolute top-[10px] z-[99999] max-md:right-[40%] right-[unset] left-[40%] max-md:left-[unset]"
        />

        {completed ? (
          <div className="absolute h-full w-full top-0 bg-[#0c121c] flex items-center justify-center">
            <Lottie
              loop={false}
              autoplay={true}
              animationData={completedCheck}
              style={{
                height: isMobile ? 320 : "260px",
              }}
              rendererSettings={isMobile ? {} : rendererSettings}
            />
          </div>
        ) : (
          <>
            <FaceAnimationProve
              isCircle={minimizeCamera}
              isScanned={completed}
              handleAnimationComplete={handleAnimationComplete}
              fullScreen={true}
            >
              <div className="text-[30px] absolute top-16 w-full text-white text-center z-[999999999] py-2 rounded-lg">
                {status || "Scan your passport"}
              </div>
              <p className="text-[14px] absolute top-[7rem] w-full text-white text-center z-[999999999] py-2 rounded-lg">
                Position your passport in the frame
              </p>
              <div className="camera-container">
                {/* Overlay for face recognition */}
                <div className="camera-overlay">
                  {/* Five dots on the face */}
                  {/* Face recognition box */}
                  <div className="recognition-box">
                    {/* Animated corners */}
                    <div className="corner top-left white-border"></div>
                    <div className="corner top-right  white-border"></div>
                    <div className="corner bottom-left  white-border"></div>
                    <div className="corner bottom-right  white-border"></div>
                  </div>
                </div>
              </div>
              {overlay && (
                <div className="w-full h-full flex z-[999999999] items-center justify-center absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                  <img
                    src={frontDlImage}
                    alt="dl_image"
                    className="w-[70%] filter invert-[100%]"
                  />
                </div>
              )}
              <video
                id="userVideo"
                muted
                autoPlay
                playsInline
                className={`w-full h-full object-cover`}
              />
            </FaceAnimationProve>
          </>
        )}
      </div>
    </>
  );
};

export default PassportScan;
