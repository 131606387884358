import Lottie, { LottieRefCurrentProps } from "lottie-react";
import faceScan from "Animations/1-Selfie/JSON/Scan-face.json";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import NoHat from "assets/no-hat.svg";
import Sun from "assets/sun.svg";
import ClutteredBg from "assets/cluttered-bg.svg";
import BackButton from "../components/backButton";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import Stepper from "./Stepper";
import Layout from "common/layout";
import SwitchDeviceSelect from "common/components/switchDeviceSelect";
import { useContext, useRef } from "react";
import CloseButton from "common/components/closeButton";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import { UAParser } from 'ua-parser-js';

type Props = {
  heading?: string;
};

const instructions = [
  {
    image: NoHat,
    heading: "Take off glasses",
    text: "Ensure nothing covers your face",
  },
  {
    image: ClutteredBg,
    heading: "Uncluttered backgrounds",
    text: "Ensure just your face is in the frame",
  },
  {
    image: Sun,
    heading: "Ensure good lighting",
    text: "Your face isn’t backlit by a light source",
  },
];

const style = { height: window.innerWidth <= 767 ? 200 : 250 };
const rendererSettings = {
  preserveAspectRatio: "xMaxYMin slice",
};

function FaceScanningIntro(Props: Props) {
  const { browser } = UAParser(navigator.userAgent);
  const isMobileSafari = browser?.name === "Mobile Safari"
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const Instructions = pageConfig?.faceInstructions || instructions;
  const { navigateToNextPage } = useNavigation();
  const onNavigate = () => {
    if (pageConfig) {
      if (["predictAge", "predictAge-Dl"]?.includes(context?.loginOption)) {
        navigateToNextPage("", "/predict-age", "/face-scan-intro");
        return;
      }
      navigateToNextPage();
    } else {
      navigateWithQueryParams("/face-scan");
    }
  };
  return (
    <Layout>
      <div className="px-10 py-8 max-md:p-[20px]">
        <div className="flex justify-between relative">
          <BackButton />
          <div className="flex items-center justify-center w-full">
            <Stepper step={1} />
          </div>
          <div>{/* {Empty div to adjust space} */}</div>
          <CloseButton />
        </div>
        <div className="mt-2">
          <Label className="text-[28px] font-[500] mt-[20px] text-primaryText block max-md:text-[24px]">
            {pageConfig?.title || "Take a selfie to verify identity"}{" "}
          </Label>
        </div>
        <div className="text-center overflow-auto mt-1 p-1">
          <div className="ps-8 pe-8 max-md:p-[10px] max-md:pt-[0px]">
            <Label className="text-[14px] font-[400] text-secondaryText">
              {pageConfig?.subheading ||
                "On the next screen, hold your phone at eye level"}
            </Label>
          </div>
          <div className="mt-5 rounded-[10px] overflow-hidden">
            <Lottie
              animationData={faceScan}
              autoplay={true}
              loop={2}
              style={style}
              rendererSettings={rendererSettings}
              lottieRef={lottieRef}
              onDOMLoaded={() => lottieRef.current?.setSpeed(2)}
              onComplete={() => onNavigate()}
            />
          </div>
          <div
            className={`mt-5 max-md:mt-6 overflow-auto max-md:h-[calc(200px_-80px)] ${
              window.innerWidth < 767
                ? isMobileSafari
                  ? " [@media_((min-height:800px)_and_(max-height:900px))]:h-[calc(200px_-10px)]"
                  : "[@media_((min-height:700px)_and_(max-height:730px))]:h-[calc(200px_-50px)] [@media_((min-height:731px)_and_(max-height:750px))]:h-[calc(200px_-10px)] [@media_((min-height:751px)_and_(max-height:800px))]:h-[calc(200px_-0px)]"
                : ""
            }`}
          >
            {" "}
            <div className="flex max-md:flex-col ">
              {Instructions.map((instruction: any, idx: any) => (
                <div
                  className="flex flex-col pb-4 justify-start items-center max-md:flex-row"
                  key={idx}
                >
                  <img src={instruction?.image} alt="" className="inline" />
                  <div className="flex flex-col text-center max-md:text-left max-md:ml-[20px]">
                    <Label className="text-primaryText font-[500] text-[14px]">
                      {instruction?.heading}
                    </Label>
                    <Label className="text-secondaryText font-[400] text-[12px]">
                      {instruction?.text}
                    </Label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="text-left h-[120px] absolute w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset]">
        <div>
          <Button
            className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
            onClick={() => onNavigate()}
          >
            {pageConfig?.buttonText || "Start"}
          </Button>
        </div>
        <div className="mt-[10px]">
          <SwitchDeviceSelect />
        </div>
      </div>
    </Layout>
  );
}

export default FaceScanningIntro;
