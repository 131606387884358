import { convertCroppedImage, enroll } from "@privateid/cryptonets-web-sdk";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
import { UserContext } from "context/userContext";
import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

let skipAntispoofProcess = false;
let identifierGlobal: any = undefined;
let collectionNameGlobal: any = undefined;

let mf_count = 3;
let portrait: ImageData | null = null;
let max_spoof_attempt = 5;
let spoof_attempt = 0;
const useEnroll = (onSuccess: () => void) => {
  const context: any = useContext(UserContext);
  const configuration = context?.configuration;
  const [enrollStatus, setEnrollStatus] = useState("");
  const [searchParams] = useSearchParams();
  const [enrollValidationStatus, setEnrollValidationStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [enrollGUID, setEnrollGUID] = useState("");
  const [enrollPUID, setEnrollPUID] = useState("");
  const [enrollImageData, setEnrollImageData] = useState<any>("");

  max_spoof_attempt = searchParams.get("spoof_attempt")
    ? Number(searchParams.get("spoof_attempt"))
    : max_spoof_attempt;

  const mf_count_override = searchParams.get("mf_count")
    ? Number(searchParams.get("mf_count"))
    : mf_count;
  let enrollTokenCurrent;
  const callback = async (result: any) => {
    console.log("enroll callback hook result:", result);
    console.log("skipping antispoof?", skipAntispoofProcess);
    const token = result.mf_token;
    if (token) {
      setProgress((p) => {
        if (p >= 100) return 100;
        return p + 100 / mf_count_override;
      });
    } else {
      setProgress(0);
    }
    if (result.enroll_performed) {
      setEnrollGUID(result.guid);
      setEnrollPUID(result.puid);
      setProgress(100);
      setEnrollValidationStatus("Success");
      onSuccess();
    } else if (result?.antispoof_status === 1) {
      spoof_attempt++;
      setProgress(0);
      if (spoof_attempt >= max_spoof_attempt) {
        alert("Spoof attempt exceeded");
        window.location.href = "/";
      } else {
        enrollUserOneFa(
            result.mf_token,
            skipAntispoofProcess,
            collectionNameGlobal,
            identifierGlobal
        );
      }
    } else {
      setEnrollValidationStatus(
          getStatusMessage(result.face_validation_status)
      );
      setEnrollStatus(result.face_validation_status);
      enrollUserOneFa(
          result.mf_token,
          skipAntispoofProcess,
          collectionNameGlobal,
          identifierGlobal
      );
    }
  };

  const enrollUserOneFa = async (
      token = "",
      skipAntispoof = false,
      collectionName = "",
      identifier = ""
  ) => {
    console.log("ENROLLING");
    enrollTokenCurrent = token;
    skipAntispoofProcess = skipAntispoof;
    collectionNameGlobal = collectionName;
    identifierGlobal = identifier;
    // eslint-disable-next-line no-unused-vars
    const bestImage: any = await enroll({
      callback: callback,
      config: {
        input_image_format: "rgba",
        mf_token: token,
        mf_count_override,
        skip_antispoof: searchParams.get("skipAntispoof") === "true" || !configuration?.liveness || false,
      },
    });
    if (bestImage) {
      setEnrollImageData(new ImageData(bestImage.imageData, bestImage.width, bestImage.height));
      const bestImagePortrait = await convertCroppedImage(bestImage.imageData, bestImage.width, bestImage.height);
      console.log("enroll image:", bestImagePortrait);
    }
  };

  return {
    enrollGUID,
    enrollPUID,
    enrollStatus,
    enrollValidationStatus,
    enrollUserOneFa,
    enrollImageData,
    progress,
  };
};

export default useEnroll;