import { useEffect, useRef, useState } from "react";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import Layout from "common/layout";
import { getPrivacy } from "services/api";
import { Loader2 } from "lucide-react";

function formatPrivacyPolicy(content: string) {
  // Replace "**" with HTML bold tags
  const formattedContent = content
    .replace(/\*\*(.*?)\*\*/g, (_, text) => `<strong>${text}</strong>`) // Replace **bold** markers with <strong> tags
    .replace(/\n\n/g, '</p><p class="mb-[20px]">') // Separate paragraphs with <p> tags
    // .replace(/\n/g, '<br>') // Convert line breaks into <br>
    .replace(/<\/?p>/g, (match) => `${match}`) // Ensure no extra spaces are added around <p> tags
    .replace(/ {2,}/g, " "); // Normalize multiple spaces into a single space

  return `<p class="mb-[10px]">${formattedContent.trim()}</p>`; // Wrap the entire content in a <p> tag
}

const PrivacyPolicy = () => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const consentContainerRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState<any>();
  useEffect(() => {
    // LoadPageConfiguration(context, "/privacy-policy");
    getPolicy();
  }, []);

  const getPolicy = async () => {
    const policy: any = await getPrivacy();
    setData(policy);
    console.log(policy, "policy");
  };

  if (!data)
    return (
      <div className="absolute h-full w-full left-0 top-0 z-50 flex items-center justify-center">
        <Loader2 className="animate-spin h-[55px] w-[55px] text-[#000]" />
      </div>
    );
  return (
    <Layout>
      <div className="px-10 py-6  max-md:p-[20px]">
        <div className="mt-2">
          <Label className="text-[28px] font-[500]">
            {data?.title}
          </Label>
          <Label className="text-[14px] font-[200] leading-0 block">
            Effective Date: <b>{data?.subHeading}</b>
          </Label>
        </div>
        <div
          className="text-left overflow-auto h-[410px] mt-4 max-md:h-[calc(100vh_-_18rem)]"
          ref={consentContainerRef}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: formatPrivacyPolicy(
                data?.contentBody || ""
              ),
            }}
          ></div>
        </div>
      </div>
      <div
        style={{ boxShadow: "0px -2px 8px 0px rgba(0, 0, 0, 0.08)" }}
        className="text-left h-[100px] w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-4 pb-4 max-md:h-[unset] max-md:rounded-0 max-md:p-[20px]"
      >
        <div>
          <Button
            className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
            onClick={() => navigateWithQueryParams("/")}
          >
            Continue
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
